<template>
  <div class="base-label"
       :style="`background-color:${label.color}`"
  >
    <span>{{label.name}}</span>
    <div v-if="label.info && tooltipText"
         class="base-label__info">
      <i class="base-label__info-btn"
              @click.prevent="onToggleInfo">
        <BaseSvg  name="ico-info"/>
      </i>
      <transition name="dropdown">
        <div v-if="showInfo"
             v-click-out="onClose"
             class="base-label__info-tooltip">
          <div class="base-label__info-tooltip-inner">
            <BaseSvg name="ico-corner" class="base-label__info-corner"/>
            {{tooltipText}}
          </div>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'BaseLabel',
    props: {
      label: null
    },
    data() {
      return {
        showInfo: false,
      }
    },
    computed: {
      tooltipText() {
        return this.$get(this.label, 'badge_info', '').toString().trim()
      }
    },
    methods: {
      onToggleInfo() {
        this.showInfo = !this.showInfo
      },
      onClose() {
        this.showInfo = false
      }
    }
  }
</script>